/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable camelcase */
// @ts-ignore
import $ from 'jquery';
import gsap from 'gsap';
import Plyr from 'plyr';
import AOS from 'aos';
import CustomEase from 'gsap/CustomEase.js';
gsap.registerPlugin( CustomEase );
AOS.init();

// Define the offset depending on the size of the video
// Medium videos
$( '.video-item.medium:odd' ).attr( 'data-aos-offset', '300' );

// Small videos
let i = 0;
$( '.video-item.small' ).each( function() {
	if ( i === 0 ) {
		$( this ).attr( 'data-aos-offset', '100' );
	} else if ( i === 1 ) {
		$( this ).attr( 'data-aos-offset', '200' );
	} else if ( i === 2 ) {
		$( this ).attr( 'data-aos-offset', '300' );
		i = -1;
	}
	i = i + 1;
} );

// PROJECT VIEWER
// Create a custom controls
const controls = `
<div class="plyr__controls">
    <div class="plyr__controls__left">
        <button type="button" class="plyr__control play" aria-label="Play, {title}" data-plyr="play">
            <span class="icon--pressed" role="presentation">pause</span>
            <span class="icon--not-pressed" role="presentation">play</span>
        </button>
        <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
            <span class="icon--pressed crossed" role="presentation">mute</span>
            <span class="icon--not-pressed" role="presentation">mute</span>
        </button>
        
        <button type="button" class="plyr__control" data-plyr="fullscreen">
            <span class="icon--pressed crossed" role="presentation"> fullscreen</span>
            <span class="icon--not-pressed" role="presentation">fullscreen</span>
        </button>
        
        <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
        <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
    </div>

    <div class="plyr__controls__right">
        <button type="button" class="plyr__control" aria-label="infos" data-plyr="infos">
            <span class="icon--pressed" role="presentation">awards</span>
            <span class="icon--not-pressed" role="presentation">awards</span>
        </button>
    </div>
    
    <div class="plyr__progress">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
    </div>
</div>
`;

// Create a Plyr instance
const player = new Plyr( '#player', {
	hideControls: false,
	controls,
	muted: false,
	volume: 1,
	autoplay: true,
	vimeo: { transparent: true, backgroud: true, controls: false },
	// poster : "./dist/assets/others/loading.gif",
	loop: { active: true },
} );

// Get video from hash
$( document ).ready( function() {
  const hash = window.location.hash.substring( 1 );
  if ( hash ) {
    getVideoId( hash );
  }
} );

function getVideoId( hash ) {
  // Get the client and title from the hash
  const _hashSplitMap = hash.split( '-' ).map( function( part ) {
    return part.replace( /_/g, ' ' );
  } ),
  [ client, title ] = _hashSplitMap;

  // Get the video item that matches the client and title
  const videoItem = $( '.video-item' ).filter( function() {
    const hasClient = $( this ).find( '.client' ).text().toLowerCase().indexOf( client ) !== -1;
    const hasTitle = $( this ).find( '.title' ).text().toLowerCase().indexOf( title ) !== -1;
    return hasClient && hasTitle;
  } ).first();

  // If the video item exists, open the video
  if ( videoItem ) {
    openProjectViewer.call( videoItem );
    displayTitle.call( videoItem );
  } else {
    console.error( 'No matching project' );
  }
}

// Animate the project viewer
function animateProjectViewer() {
  const tl = gsap.timeline();
  tl.to( '.full-video', {
    y: 0,
    duration: 1,
    ease: 'Power4.easeOut',
  }, 0 )
    .to( '.full-video', {
      opacity: 1,
      duration: 0.3,
      ease: 'Power4.easeOut',
      delay: 0.1,
    }, 0 )
    .to( '.video-header .wrapper', {
      opacity: 1,
      duration: 0.5,
      ease: 'Power4.easeOut',
      delay: 0.6,
    }, 0 )
    .to( '.video-header .box-controls', {
      opacity: 1,
      duration: 0.5,
      ease: 'Power4.easeOut',
      delay: 0.8,
    }, 0 );
    player.volume = 1;
}

function createGallery( projectId ) {
  // Hide the video elements
  $( '.plyr__controls' ).removeClass( 'visible' );
  $( '.plyr__video-wrapper' ).removeClass( 'visible' );

  // Get the project gallery
  $.ajax( {
    // eslint-disable-next-line no-undef
    url: myAjax.ajaxurl,
    type: 'POST',
    data: {
      action: 'get_project_gallery',
      project_id: projectId,
    },
    success( response ) {
      if ( response.success ) {
        const gallery = response.data;
        const galleryWrapper = $( '.gallery' );
        const galleryPreview = $( '.gallery-preview' );

        if ( gallery.length > 0 ) {
            // Build the gallery preview
            galleryWrapper.empty();
            galleryPreview.empty();
            gallery.forEach( function( img, index ) {
                const imgElement = $( '<img src="' + img.url + '" alt="' + img.title + '" class="gallery-preview-item" data-index="' + index + '">' );
                imgElement.on( 'click', function() {
                    onImageClick( index );
                } );
                galleryPreview.append( imgElement );
                setGalleryLineClasses();
            } );

            function setGalleryLineClasses() {
                const container = document.querySelector( '.gallery-preview' );
                const items = container.querySelectorAll( '.gallery-preview-item' );

                // Reset des classes
                items.forEach( ( item ) => {
                    item.classList.remove( 'first-line', 'last-line' );
                } );

                // Détection de la première ligne
                const firstItemTop = items[ 0 ].offsetTop;
                items.forEach( ( item ) => {
                    if ( item.offsetTop === firstItemTop ) {
                        item.classList.add( 'first-line' );
                    }
                } );

                // Détection de la dernière ligne
                const lastItemTop = items[ items.length - 1 ].offsetTop;
                items.forEach( ( item ) => {
                    if ( item.offsetTop === lastItemTop ) {
                        item.classList.add( 'last-line' );
                    }
                } );
            }

            // Appeler après le chargement de la galerie
            window.addEventListener( 'resize', setGalleryLineClasses );

            setTimeout( () => {
                setGalleryLineClasses();
            }, 150 );

          function onImageClick( imgIndex ) {
            // Build the gallery
        //   let imgIndex = 0;
        galleryPreview.empty();
        console.log( 'clicked:', imgIndex );

          const prevButtonElement = '<div class="gallery-prev"></div>';
          const nextButtonElement = '<div class="gallery-next"></div>';
          const galleryContainerElement = '<div class="gallery-container"></div>';
          const galleryCounterElement = '<div class="gallery-counter plyr__controls"></div>';

          galleryWrapper.empty().append( prevButtonElement, nextButtonElement, galleryContainerElement, galleryCounterElement );

          // Update the gallery
          // eslint-disable-next-line no-shadow
          function updateImg( imgIndex ) {
            const galleryContainer = $( '.gallery-container' );
            const galleryCounter = $( '.gallery-counter' );

            const img = '<img src="' + gallery[ imgIndex ].url + '" alt="' + gallery[ imgIndex ].title + '" class="gallery-item-img" >';
            const imgItem = '<div class="gallery-item">' + img + '</div>';
            const counter = '<span>' + ( imgIndex + 1 ) + '/' + gallery.length + '</span>';
            galleryContainer.empty().append( imgItem );
            galleryCounter.empty().append( counter );
          }

          updateImg( imgIndex );

          // Navigation buttons
          const prevButton = $( '.gallery-prev' );
          const nextButton = $( '.gallery-next' );

          prevButton.on( 'click', function() {
            imgIndex = ( imgIndex - 1 + gallery.length ) % gallery.length;
            updateImg( imgIndex );
          } );

          nextButton.on( 'click', function() {
            imgIndex = ( imgIndex + 1 ) % gallery.length;
            updateImg( imgIndex );
          } );
        }
        }
      } else {
        console.error( 'No gallery found' );
      }
    },
    error( error ) {
      console.error( 'Erreur Ajax', error );
    },
  } );
}

function setAwardsLineClasses() {
    const container = document.querySelector( '.awards-wrapper' );
    const items = container.querySelectorAll( '.awards-item' );

    // Reset des classes
    items.forEach( ( item ) => {
        item.classList.remove( 'first-line', 'last-line' );
    } );

    // Détection de la première ligne
    const firstItemTop = items[ 0 ].offsetTop;
    items.forEach( ( item ) => {
        if ( item.offsetTop === firstItemTop ) {
            item.classList.add( 'first-line' );
        }
    } );

    // Détection de la dernière ligne
    const lastItemTop = items[ items.length - 1 ].offsetTop;
    items.forEach( ( item ) => {
        if ( item.offsetTop === lastItemTop ) {
            item.classList.add( 'last-line' );
        }
    } );
}

// Get awards
function getAwards( projectId ) {
  // Get the project awards
  $.ajax( {
    // eslint-disable-next-line no-undef
    url: myAjax.ajaxurl,
    type: 'POST',
    data: {
      action: 'get_project_awards',
      project_id: projectId,
    },
    success( response ) {
      if ( response.success ) {
        const awards = response.data;
        const awardsWrapper = $( '.awards-wrapper' );

        if ( awards.length > 0 ) {
            awardsWrapper.empty();
            awards.forEach( function( img, index ) {
                const imgElement = $( '<img src="' + img.url + '" alt="' + img.title + '" class="awards-item" data-index="' + index + '">' );
                awardsWrapper.append( imgElement );
                setAwardsLineClasses();
            } );

            // Appeler après le chargement de la galerie
            window.addEventListener( 'resize', setAwardsLineClasses );
        }
      } else {
        console.error( 'No awards found' );
        $( '.plyr__controls__right button[data-plyr="infos"]' ).hide();
      }
    },
    error( error ) {
      console.error( 'Erreur Ajax', error );
    },
  } );
}

// Display player and controls
function showVideoElements() {
  // Hide gallery
  const galleryWrapper = $( '.gallery' );
  galleryWrapper.empty();

  // Show the video elements
  setTimeout( function() {
    $( '.plyr__controls' ).toggleClass( 'visible' );
  }, 800 );
  setTimeout( function() {
    $( '.plyr__video-wrapper' ).toggleClass( 'visible' );
  }, 1000 );

    // Handle infos button clicks
    setTimeout( () => {
        const infoButton = $( '.plyr__controls__right button[data-plyr="infos"]' );
        const infosModal = $( '.infos-modal' );

        infoButton.on( 'click', function() {
            infoButton.toggleClass( 'plyr__control--pressed' );
            const isPressedIcon = $( this ).hasClass( 'plyr__control--pressed' );

            if ( isPressedIcon ) {
                infosModal.show();
                player.pause();
                setTimeout( () => {
                    setAwardsLineClasses();
                }, 100 );
                infosModal.on( 'click', function() {
                    infoButton.removeClass( 'plyr__control--pressed' );
                    infosModal.hide();
                } );
            } else {
                infosModal.hide();
            }
        } );
    }, 900 );
}

// Open video from hash
function openProjectViewer() {
  // Animate the video elements
  animateProjectViewer.call( this );

  // Get the video id and project id
  const src = $( this ).attr( 'data-vimeo' );
  const projectId = $( this ).attr( 'data-id' );

  if ( src === undefined || src === null ) {
    // If no video source, create the gallery
    createGallery( projectId );
  } else {
    // Update the video source
    player.once( 'ready', function() {
      showVideoElements();
      getAwards( projectId );

      player.source = {
        type: 'video',
        sources: [ {
          src,
          provider: 'vimeo',
        } ],
      };
    } );

    // Forcing player to reload the source manually
    player.source = {
      type: 'video',
      sources: [ {
        src,
        provider: 'vimeo',
      } ],
    };
  }
}

// Open video from click
$( '.video-item' ).on( 'click', handleClick );

function handleClick() {
  // Update url hash
  const client = $( this ).find( '.client' ).text();
  const title = $( this ).find( '.title' ).text();
  const slug = client.replace( /\s+/g, '_' ) + '-' + title.replace( /\s+/g, '_' );
  window.location.hash = slug.toLowerCase();

  // Open the video
  openProjectViewer.call( $( this ) );
}

// Display the project title
$( '.video-item' ).on( 'click', displayTitle );

function displayTitle() {
  const client = $( this ).find( '.client' ).text();
  const title = $( this ).find( '.title' ).text();
  $( '.client-overlay' ).text( client );
  $( '.title-overlay' ).text( title );
}

// Close project viewer on click
$( '.control.close' ).on( 'click', closeProjectViewer );

function closeProjectViewer() {
  // Hide the video elements
  setTimeout( function() {
    $( '.plyr__controls' ).removeClass( 'visible' );
    $( '.infos-modal' ).hide();
  }, 100 );
  setTimeout( function() {
    $( '.plyr__video-wrapper' ).removeClass( 'visible' );
  }, 50 );
  setTimeout( function() {
    player.pause();
  }, 600 );

  // Animate the project viewer
  const tl = gsap.timeline();
	tl.to( '.full-video', {
		opacity: 0,
		duration: 0.15,
		delay: 0.9,
	}, 0 )
		.to( '.video-header .wrapper', {
			opacity: 0,
			duration: 0.3,
			ease: 'Power4.easeOut',
			delay: 0.3,
		}, 0 )
		.to( '.video-header .box-controls', {
			opacity: 0,
			duration: 0.3,
			ease: 'Power4.easeOut',
			delay: 0.2,
		}, 0 )
		.to( '.full-video', {
			y: '-101%',
			delay: 0.5,
			duration: 0.6,
			ease: 'Power4.easeIn',
		}, 0 );

  // Remove the hash from the url
  history.replaceState( null, null, window.location.pathname );

  // Remove the gallery
    $( '.gallery' ).empty();
    $( '.gallery-preview' ).empty();
}

// Close project viewer on escape key
$( document ).on( 'keydown', function( e ) {
  if ( e.key === 'Escape' ) {
    closeProjectViewer();
  }
} );

// PROJECTS PREVIEWS
// Handle hover on projects previews
$( function() {
  // On mouse enter
	function handleMouseEnter() {
		$( '.img-wrapper img', this ).addClass( 'hide' );
		$( '#vid', this ).get( 0 ).currentTime = 0;
		$( '#vid', this ).trigger( 'play' );
	}
  // On mouse leave
	function handleMouseLeave() {
		$( '.img-wrapper img', this ).removeClass( 'hide' );

		$( '#vid', this ).trigger( 'pause' );
	}
	$( '.video-item' ).hover( handleMouseEnter, handleMouseLeave );
} );

// MENU ANIMATION
// Smaller screens
if ( screen.width < 960 ) {
	$( '.filter.not-clicked' ).on( 'click', function() {
		$( this ).css( 'display', 'none' );
		$( '.filter-close' ).css( 'display', 'block' );
		const tl = gsap.timeline();
		tl.to( 'header', {
			height: 400,
			duration: 0.45,
			ease: 'circ',
		}, 0 )
			.to( '.header.secondary', {
				display: 'flex',

				duration: 0.01,
				ease: 'circ',
			}, 0 )
			.fromTo( '.header.secondary', {
				top: 30,
			}, {

				top: 0,
				duration: 0.6,
				ease: 'cubic',
			}, 0 )
			.fromTo( '.header.secondary', {
				opacity: 0,
			}, {
				opacity: 1,

				duration: 0.6,
				ease: 'cubic',
			}, 0 );

		$( '.filter' ).addClass( 'filter-fat' );
		$( 'main' ).addClass( 'blured' );
		// $('.filter').unbind('click');
		$( '.filter.clicked' ).bind( 'click' );
	} );

	$( '.filter-close' ).on( 'click', function() {
		$( this ).css( 'display', 'none' );
		$( '.filter' ).css( 'display', 'block' );
		$( this ).removeClass( 'clicked' );
		$( this ).addClass( 'not-clicked' );

		const tl1 = gsap.timeline();
		tl1.to( 'header', {
			height: 60,
			duration: 0.5,
			ease: 'circ',
		}, 0 )
			.to( '.header.secondary', {
				display: 'none',
				delay: 0.7,
				duration: 0.01,
				ease: 'circ',
			}, 0 )
			.to( '.header.secondary', {

				top: 30,
				duration: 0.6,
				ease: 'cubic',
			}, 0 )
			.to( '.header.secondary', {
				opacity: 0,

				duration: 0.6,
				ease: 'cubic',
			}, 0 );
		$( '.filter' ).removeClass( 'filter-fat' );
		$( 'main' ).removeClass( 'blured' );
	} );
}

if ( screen.width > 960 ) {
  // Get the real auto height of the header to animate it to the good size
  $( 'header' ).css( 'height', 'auto' );
  const autoHeight = $( 'header' ).height();
  $( 'header' ).css( 'height', '60px' );

  // Animate the menu on hover (with the good header height)
	$( '.filter' ).on( 'mouseenter', function() {
		const tl = gsap.timeline();
		tl.to( 'header', {
			height: autoHeight + 60,
			duration: 0.45,
			ease: 'circ',
		}, 0 )
			.to( '.header.secondary', {
				display: 'flex',

				duration: 0.01,
				ease: 'circ',
			}, 0 )
			.fromTo( '.header.secondary', {
				top: 30,
			}, {

				top: 0,
				duration: 0.6,
				ease: 'cubic',
			}, 0 )
			.fromTo( '.header.secondary', {
				opacity: 0,
			}, {
				opacity: 1,

				duration: 0.6,
				ease: 'cubic',
			}, 0 );

		$( '.filter' ).addClass( 'filter-fat' );
		$( 'main' ).addClass( 'blured' );
	} );
	$( 'header' ).on( 'mouseleave', function() {
		const tl = gsap.timeline();
		tl.to( 'header', {
			height: 60,
			duration: 0.5,
			ease: 'circ',
		}, 0 )
			.to( '.header.secondary', {
				display: 'none',
				delay: 0.7,
				duration: 0.01,
				ease: 'circ',
			}, 0 )
			.to( '.header.secondary', {

				top: 30,
				duration: 0.6,
				ease: 'cubic',
			}, 0 )
			.to( '.header.secondary', {
				opacity: 0,

				duration: 0.6,
				ease: 'cubic',
			}, 0 );
		$( '.filter' ).removeClass( 'filter-fat' );
		$( 'main' ).removeClass( 'blured' );
		// $('main').toggleClass('active-main')
		// $('dark.bg').toggleClass('active-bg')
	} );
}

// INFO PANEL (I think this is useless now, but in case it is still needed, I will leave it here)
$( '.header .info-button' ).click( function() {
	$( '.info-panel' ).toggleClass( 'panel-active' );
} );

// INTRO ANIMATION
// Create custom eases
CustomEase.create( 'cubic', '0.65, 0, 0.35, 1' );
CustomEase.create( 'bezier', '0.87, 0, 0.13, 1' );
CustomEase.create( 'circ', '0.85, 0, 0.15, 1' );

// Intro animation
$( function() {
	// If the user has not been here before, intro animation
	if ( ! sessionStorage.getItem( 'beenHereBefore' ) ) {
    // Hide the main content
		$( 'main' ).css( 'opacity', '0' );
    // Store the fact that the user has been here before
		sessionStorage.setItem( 'beenHereBefore', 'true' );

    // Different animations depending on the screen size
		if ( screen.width > 960 ) {
			gsap.fromTo( 'main', { opacity: 0 }, { opacity: 1, duration: 0.3, delay: 1.5 }, 0 );
			gsap.fromTo( 'body', { overflow: 'hidden' }, { overflow: 'auto', duration: 0.8, delay: 1 }, 0 );
			gsap.fromTo( '.header h1', { opacity: 0 }, { opacity: 1, duration: 0.6, delay: 0.6, ease:
        'cubic' }, 0 );
			gsap.fromTo( '.header h1', { left: 80, transformOrigin: 'left' }, { left: 0, duration: 1.2, delay: 0.6, ease:
          'cubic', autoRound: false }, 0 );
			gsap.fromTo( '#deliveryAnimation', { height: 80, left: -5 }, { height: 24, left: 0, duration: 0.8, delay: 1, ease:
        'cubic', autoRound: false }, 0 );
			gsap.fromTo( '.logo-anim', { fontSize: 100 }, { fontSize: 32, duration: 0.8, delay: 1, ease:
        'cubic', autoRound: false } );
			gsap.fromTo( '.header', { top: 60 }, { top: 0, duration: 0.8, delay: 1, autoRound: false, ease:
          'cubic' }, 0 );

			gsap.fromTo( '.header .hide', { opacity: 0 }, { opacity: 1, duration: 0.6, delay: 1.2, autoRound: false, ease:
          'cubic' }, 0 );
			gsap.fromTo( '.header .hide', { width: '0%' }, { width: '66.6%', duration: 0.1, delay: 1 }, 0 );

			gsap.fromTo( 'header nav.primary .wrapper:first-of-type', { width: '100%' }, { width: '33.33%', duration: 0.2, ease:
        'Expo.easeOut', delay: 1.3 }, 0 );

			gsap.fromTo( 'header', { height: '100vh' }, { height: 60, duration: 0.6, delay: 1.3, ease:
        'bezier', autoRound: false }, 0 );
		} else {
			gsap.fromTo( 'main', { opacity: 0 }, { opacity: 1, duration: 0.3, delay: 1.5 }, 0 );
			gsap.fromTo( 'body', { overflow: 'hidden' }, { overflow: 'auto', duration: 0.8, delay: 1 }, 0 );
			gsap.fromTo( '.header h1', { opacity: 0 }, { opacity: 1, duration: 0.6, delay: 0.6, ease:
        'cubic' }, 0 );
			gsap.fromTo( '.header h1', { left: 40, transformOrigin: 'left' }, { left: 0, duration: 1.2, delay: 0.6, ease:
          'cubic', autoRound: false }, 0 );
			gsap.fromTo( '#deliveryAnimation', { height: 40, left: -5 }, { height: 24, left: 0, duration: 0.8, delay: 1, ease:
        'cubic', autoRound: false }, 0 );
			gsap.fromTo( '.logo-anim', { fontSize: 30 }, { fontSize: 18, duration: 0.8, delay: 1, ease:
        'cubic', autoRound: false } );
			gsap.fromTo( '.header', { top: 80 }, { top: 0, duration: 0.8, delay: 1, autoRound: false, ease:
          'cubic' }, 0 );

			gsap.fromTo( '.header .hide', { opacity: 0 }, { opacity: 1, duration: 0.6, delay: 1.2, autoRound: false, ease:
          'cubic' }, 0 );
			gsap.fromTo( '.header .hide', { width: '0%' }, { width: 'inherit', duration: 0.1, delay: 1.5 }, 0 );

			gsap.fromTo( 'header', { height: '100vh' }, { height: 60, duration: 0.6, delay: 1.3, ease:
          'bezier', autoRound: false }, 0 );

			gsap.fromTo( 'header nav.primary .wrapper:first-of-type', { width: '100%' }, { width: 'inherit', duration: 0.2, ease:
          'Expo.easeOut', delay: 2.2 }, 0 );
		}
	}
} );

// If the user has been here before, no intro animation
if ( sessionStorage.getItem( 'beenHereBefore' ) ) {
	if ( $( 'body' ).hasClass( 'home' ) ) {
		$( '.header .hide' ).css( 'opacity', '1' );
		$( 'h1' ).css( 'opacity', '1' );
		$( 'header' ).css( 'height', '60px' );
		$( 'main' ).css( 'opacity', '1' );
		$( '#deliveryAnimation' ).css( 'display', 'none' );
		$( '.no-home' ).css( 'display', 'block' );
		$( '.site-logo' ).css( 'height', '24px' );
	}
}

// If the page is an archive page, change the filter link
if ( $( 'body' ).hasClass( 'archive' ) ) {
	if ( screen.width > 960 ) {
		const filter = $( '.active-menu' ).html();
		$( '.active-menu' ).attr( 'href', '../../' );
		$( '.filter' ).html( filter );
	} else {
		const filter = $( '.active-menu' ).html();
		$( '.mobile-filter' ).attr( 'href', '../../' );
		$( '.mobile-filter' ).html( filter + '\u00A0' + '\u00A0' + 'x' );
	}
}
